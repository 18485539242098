import React from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from "primereact/button";

const BotaoConsultaLote = (props) => {

    function onHide(){
        props.onClick();
        props.atualizarGrid(true, false)
    }

    async function consultaServicoRetorno(protocolo) {
        try {
            setTimeout(onHide, 1000);
            const response = await fetch("/EsocialAPI/ConsultaRetorno/", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // Changed content type to URL-encoded
                    'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                },
                body: new URLSearchParams({ "protocolo": protocolo }) // Properly format the body as URL-encoded
            });
    
            const respostaText = await response.text();
            const mensagemDTO = JSON.parse(respostaText);
            let arrMensagem = [];
            arrMensagem.push({ severity: mensagemDTO.tipoMensagem, detail: mensagemDTO.mensagem });
    
            if (arrMensagem.length > 0) {
                props.toast.show(arrMensagem);
            }
        } catch (error) {
            console.log(error);
        } finally {
            onHide();
        }
    }
    

    return (

        <div>
            <Button style={{ marginRight: '.5em' }} type="button" icon="pi pi-replay" onClick={(e) => consultaServicoRetorno(props.protocolo)} tooltip="Atualizar Lote" tooltipOptions={{position: 'top'}} disabled={props.disabled}></Button>
        </div>
    )
}

export default BotaoConsultaLote;
