import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../../css/statusTabela.css';
import $ from 'jquery';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Preview } from '../../sessoes/Processamento/preview/preview';
import { EnvioLote } from '../Processamento/dialog/EnvioLote';
import { Messages } from 'primereact/messages';
import { Checkbox } from 'primereact/checkbox';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import { Badge } from 'primereact/badge';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Toolbar } from 'primereact/toolbar';
import { Panel } from 'primereact/panel';
import { LoteView } from '../Processamento/dialog/LoteView';
import { configure, GlobalHotKeys } from 'react-hotkeys';
import Utils from "../../utils/Util";
import CheckEventoProntoNovo from '../../componentes/CheckEventoProntoNovo';
import grupoService from '../../services/grupoService';
import eventoService from '../../services/eventoService';
import filtroPersonalizadoService from '../../services/filtroPersonalizadoService';
import tipoServidorService from '../../services/tipoServidorService';
import processamentoService from '../../services/processamentoService';


configure({
    ignoreTags: ['input', 'select', 'textarea'], // Ignorar atalhos quando essas tags estiverem focadas
    ignoreEventsCondition: () => false, // Sempre processar atalhos, independentemente de outros eventos
  });

export class ProcessamentoFila extends Component {
    constructor() {
        super();
        this.state = {
            lista: []
            , xmlPreview: ''
            , percentualExportacao: 1
            , interval: null
            , filtroAberto: true
            , dropdownEventos: []
            , dropdownEventosExcluidos: []
            , dropdownGrupoEventos: []
            , dropdownTipoServidor: []
            , idEventoFila: null
            , grupo: null
            , cpf: null
            , isCollapsed: false
            , tipoServidor: null
            , evento: null
            , eventoExcluido: null
            , eventoExclusaoSelecionado: false
            , codEvento: null
            , objDetalhe: ''
            , selecionados: []
            , evtselecionados: false
            , modalMudarAcao: false
            , dropdownAcaoModal: null
            , visible: false
            , pagina: 0
            , rows: 50
            , total: 0
            , status: null
            , visualizarXml: false
            , loading: true
            , rspImportacao: { tipoMsg: null, descMensagem: null }
            , checked: false
            , eventosSelecionados: []
            , selecionaTodosEvts: false
            , acao: null
            , idEventoFilaFiltro: ''
            , idLote: null
            , first: 0
            , showProgressBar: false
            , blkEnvioGeral: 'block'
            , blkAnimacaoEnvioGeral: 'none'
            , situacao: null
            , tipoAfastamento: null
            , anoPerApur: null
            , mesPerApur: null
            , indApuracao: null
            , statusInvalidos: ['RECEBIDO', 'AGUARDANDO_RETORNO', 'EXCLUIDO']
            , desabilitaEnvio: true
            , desabilitaMudarAcao: true
            , realizandoEnvio: false
            , isValidating: false
            , filtrosPersonalizados: []
            , emptyMessage: ''
        };
        this.atualizaGrid = this.atualizaGrid.bind(this)
        this.errorTemplate = this.errorTemplate.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.renderCheckBoxEnvio = this.renderCheckBoxEnvio.bind(this);
        this.onEventCheckChange = this.onEventCheckChange.bind(this);
        this.onCheckAllChange = this.onCheckAllChange.bind(this);
        this.renderFooterEnvioLote = this.renderFooterEnvioLote.bind(this);
        this.enviarEventosEsocial = this.enviarEventosEsocial.bind(this);
        this.resetaModalEnvioLote = this.resetaModalEnvioLote.bind(this);
        this.renderLoandingValidacaoEventos = this.renderLoandingValidacaoEventos.bind(this)
        this.handleEnvioLoteClick = this.handleEnvioLoteClick.bind(this)
        this.actionTemplate = this.actionTemplate.bind(this);
    }

    componentDidMount() {
        this.atualizaGrid(false, true);
        
        grupoService.carregarDropdownGrupoEvento((resposta) => {
            const filteredData = resposta.filter(item => ['TABELA', 'NÃO_PERIÓDICO', 'PERIÓDICO'].includes(item.label));
            filteredData.unshift({ label: "Todos", value: "" });
            this.setState({ dropdownGrupoEventos: filteredData });
        });
        
        this.carregarDropdownEventoRequest(null);

        filtroPersonalizadoService.consultaFiltroPersonalizado((resposta) => {
            this.setState({ filtrosPersonalizados: resposta })
        });

        tipoServidorService.carregarDropdownTipoServidor((resposta) => {
            const opcoes = resposta.map((reg) => {
                return { descricao: reg.descricao, value: reg.idTipoServidor }
            })
            opcoes.unshift({ descricao: "Todos", value: "" });
            this.setState({ dropdownTipoServidor: opcoes })
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    carregarDropdownEventoRequest(grupoEvento, isEventosExclusao) {
        eventoService.carregarDropdownEvento(
            grupoEvento,
            (resposta) => {
                const opcoes = resposta.map((evento) => {
                    return { label: evento.codigo + ' - ' + evento.nome, value: evento.idEventoEsocial, codigo: evento.codigo }
                })

                opcoes.unshift({ label: "Todos", value: "" });

                if(isEventosExclusao){
                    this.setState({ dropdownEventosExcluidos: opcoes }); 
                }else{
                    this.setState({ dropdownEventos: opcoes }); 
                }
            }
        );
    }

    handleStatusChange(status) {
        this.setState({ status });
    }

    handleGrupoEventoChange(grupo) {
        if(grupo === ''){
            this.carregarDropdownEventoRequest(null);
        }else{
            this.carregarDropdownEventoRequest(grupo);
        }
        this.setState({ grupo });
        //"zerar" filtros
        this.handleEventoChange(null)
        this.handleStatusChange(null)
        this.handleAcaoChange(null)
        //this.handleObjDetalheChange('')
        this.handleIdEventoFilaChange(null)
        this.handleSituacaoChange(null)
        this.handleTipoServidorChange(null)
        this.handleMesChange(null)
        this.handleAnoChange(null)
        this.handleIndApuracaoChange(null)
        
        let grupoSelecionado = this.state.dropdownGrupoEventos.find(item => item.value === grupo)?.label
        if(grupoSelecionado === 'TABELA'){
            this.handleCpfChange(null)
        }
    }

    handleEventoChange(evento) {
        console.log(evento)
        this.setState({ evento });
        const codigoEvento = this.state.dropdownEventos.find(item => item.value === evento)?.codigo;
        this.setState({ codEvento: codigoEvento });

        if(evento!=null && evento!=undefined){
            const eventoExclusaoSelecionado = this.state.dropdownEventos.find(item => item.value === evento)?.label.includes('3000');

            if(eventoExclusaoSelecionado){
                this.setState({ eventoExclusaoSelecionado: true });
                this.carregarDropdownEventoRequest(null, eventoExclusaoSelecionado);
            } else {
                this.setState({ eventoExcluido: null });
                this.setState({ eventoExclusaoSelecionado: false });
            }
        }

    }

    handleEventoExcluidoChange(evento) {
        this.setState({ eventoExcluido: evento });
    }

    handleAcaoChange(acao) {
        this.setState({ acao });
    }

    handleMesChange(mesPerApur) {
        this.setState({ mesPerApur });
    }

    handleAnoChange(anoPerApur) {
        this.setState({ anoPerApur });
    }

    handleIndApuracaoChange(indApuracao) {
        this.setState({ indApuracao });
    }

    handleObjDetalheChange(objDetalhe) {
        this.setState({ objDetalhe: objDetalhe.toUpperCase() });
    }

    handleIdEventoFilaChange(idEventoFila) {
        this.setState({ idEventoFila });
    }

    handleCpfChange(cpf) {
        let cpfNumero = cpf;
        if (cpf !== null) {
            cpfNumero = cpf.replace(/\D/g, '');
        } else {
            cpfNumero = '';
        }
        this.setState({ cpf: cpfNumero });
    }
    

    handleIdLoteChange(idLote) {
        this.setState({ idLote });
    }

    handleTipoServidorChange(tipoServidor) {
        this.setState({ tipoServidor });
    }

    handleSituacaoChange(situacao) {
        this.setState({ situacao });
    }

    handleIsCollapsed(isCollapsed) {
        this.setState({ isCollapsed });
    }

    handleClick() {
        this.setState({ visible: true });
    }

    handleEnvioLoteClick() {
        //this.validarEventos(this.state.eventosSelecionados);
        this.setState({ evtselecionados: true });
    }

    handleMudarAcaoClick() {
        this.setState({ modalMudarAcao: true });
    }

    handleDropdownAcaoModalChange(dropdownAcaoModal) {
        this.setState({ dropdownAcaoModal });
    }

    rowClass(data) {
        return {
            'row-accessories': data.situacao === 'Accessories'
        }
    }

    displaySelection(data) {
        if (!data || data.length === 0) {
            return <div style={{ textAlign: 'left' }}> Selecione ao menos 1 evento.</div>;
        } else {
            if (data instanceof Array) {
                return (
                    <ul style={{ textAlign: 'left', margin: 0 }}>
                        {data.map((evento, i) =>
                            <li key={evento.idObjeto}>{evento.codEventoEsocial + ' - ' + evento.objetoAlvoEvento}</li>)
                        }
                    </ul>
                );
            }
        }
    }
    retornaEstiloStatus(data) {
        return {
            'tblnovo': data.situacao === 'NOVO',
            'tblenviado': data.situacao === 'ENVIADO',
            'tblerro': data.situacao === 'ERRO',
            'tblaguardando': data.situacao === 'AGUARDANDO_RETORNO',
            'tblrecebidoocorrencias': data.situacao === 'RECEBIDO_COM_OCORRENCIAS',
            'tblrecebido': data.situacao === 'RECEBIDO',
            'tblcancelado': data.situacao === 'CANCELADO',
            'tblrejeitado': data.situacao === 'REJEITADO',
            'tblpendente': data.situacao === 'PENDENTE',
            'tblexcluido': data.situacao === 'EXCLUIDO'
        }
    }

    enviarEventosEsocial() {


        this.setState({ realizandoEnvio: true });
        let idEventoFilas = [];
        var i;
        for (i = 0; i < this.state.eventosSelecionados.length; i++) {
            idEventoFilas[i] = this.state.eventosSelecionados[i].idEventoFila;
        }
        $("#divEvtEnviados").fadeIn();
        $("#divTabelaEventosSelecionados").fadeOut();
        this.setState({ blkAnimacaoEnvioGeral: 'block' });
        $('#pgrEnviar').css("display", "block");

        processamentoService.enviarEvento(idEventoFilas, (resposta)=>{
            $('#pgrEnviar').css("display", "none");
            this.setState({ selecionados: [], evtselecionados: false, realizandoEnvio: false, blkAnimacaoEnvioGeral: 'none' });
            this.atualizaGrid(false);
            $("#divEvtEnviados").fadeOut();
        })
    }

    mudarAcaoEventos() {
        let idEventoFilas = [];
        var i;
        for (i = 0; i < this.state.eventosSelecionados.length; i++) {
            idEventoFilas[i] = this.state.eventosSelecionados[i].idEventoFila;
        }

        processamentoService.mudarAcaoEventos(idEventoFilas, this.state.dropdownAcaoModal, (resposta) =>{
            this.setState({ selecionados: [], modalMudarAcao: false, dropdownAcaoModal: null });
            this.atualizaGrid(false);
        })
    }

    validarEventos(eventos) {
        this.setState({ isValidating: true })
        const idEventoFila = eventos.map((obj) => {
            return obj.idEventoFila;
        });
        //Montando o objeto que a API espera receber
        const evtsLote = { listaIdsEventosLote: idEventoFila }

        processamentoService.validarEventos(evtsLote, (resposta) =>{
            this.setState({ isValidating: false })
        })
    }

    atualizaGrid(bolOnpage, isAtualizacaoInicial) {
        this.setState({ emptyMessage: 'Utilize os filtros acima para retornar resultados.' });
        var filtro = {
            grupo: isAtualizacaoInicial ? 1 : this.state.grupo,
            idTipoEvento: this.state.evento,
            status: this.state.status,
            objDetalhe: this.state.objDetalhe.trim(),
            acao: this.state.acao,
            idEventoFila: this.state.idEventoFila,
            idLote: this.state.idLote,
            isEvtPronto: this.state.situacao,
            tipoAfastamento: this.state.tipoAfastamento,
            anoPerApur: this.state.anoPerApur,
            mesPerApur: this.state.mesPerApur,
            indApuracao: this.state.indApuracao,
            idTipoServidor: this.state.tipoServidor,
            cpf: this.state.cpf,
            idEventoEsocialExcluido: this.state.eventoExcluido,
            codigoEventoEsocial: this.state.codEvento
        };

        this.setState({ loading: true });
        let pagina = 0;
        if (bolOnpage === false) {
            pagina = this.state.pagina - 1;
            if (pagina < 0) {
                pagina = 0;
            }
        } else {
            pagina = this.state.pagina;
        }
        var dados = { pagina: pagina, filtro: filtro };

        filtroPersonalizadoService.consultaFiltroPersonalizado((resposta) => {
            this.setState({ filtrosPersonalizados: resposta })
        });

        processamentoService.filtrarProcessamentoFila(dados, (resposta) => {
            this.setState({
                loading: false,
                lista: resposta,
                total: resposta.totalPaginas,
                pagina: resposta.pagina,
                selecionados: [],
                evtselecionados: false,
                selecionaTodosEvts: false,
                eventosSelecionados: [],
                tiposServidor: resposta.tiposServidor
            });
            if (resposta.totalPaginas === null) {
                this.setState({ emptyMessage: 'Nenhum evento encontrado na fila.' });
            }
        })       
    }

    alertaImportacao() {
        if (this.state.rspImportacao.tipoMsg === 'SUCESSO') {
            this.showMessage(this.state.rspImportacao.tipoMsg, this.state.rspImportacao.descMensagem, 'success');
        }
        if (this.state.rspImportacao.tipoMsg === 'ERRO') {
            this.showMessage(this.state.rspImportacao.tipoMsg, this.state.rspImportacao.descMensagem, 'danger');
        }
        if (this.state.rspImportacao.tipoMsg === 'ALERTA') {
            this.showMessage(this.state.rspImportacao.tipoMsg, this.state.rspImportacao.descMensagem, 'warning');
        }
    }

    showMessage(titulo, mensagem, tipoMensagem) {
        this.messages.show({ severity: tipoMensagem, summary: titulo, detail: mensagem });
    }

    errorTemplate(rowData, column) {
        let status = rowData.situacao;
        let color = status === 'REJEITADO' ? 'red' : 'black';
        let retorno = '';
        if (status === '') {
            retorno = (<div>
                <span style={{ color: color, fontWeight: 'bold' }}>{rowData['situacao']}</span>
            </div>)
        } else {
            retorno = (<div>
                <span style={{ color: color }}>{rowData['situacao']}</span>
            </div>)
        }
        return retorno
    }

    VisualizarMsg(id) {
        alert(id);
    }

    updateListaEvento = (updatedListaEvento) => {
        this.setState(prevState => ({
            lista: {
                ...prevState.lista,
                listaEvento: updatedListaEvento
            }
        }));
    }

    actionTemplate(rowData, column) {
        let retorno = '';
        retorno = (
            <div>
                <Preview
                    idEventoFila={rowData['idEventoFila']}
                    eventoEsocial={rowData['eventoEsocial']}
                    codEventoEsocial={rowData['codEventoEsocial']}
                    idEventoEsocial={rowData['idEventoEsocial']}
                    listaErros={rowData['listaErros']}
                    eventoPronto={rowData['isReady']}
                    statusEvento={rowData['situacao']}
                    atualizaGrid={this.atualizaGrid}
                    rowData={rowData}
                    listaEvento={this.state.lista.listaEvento}
                    updateListaEvento={this.updateListaEvento}
                />
            </div>
        )
        return retorno
    }

    onCheckAllChange(e) {
        this.setState({ selecionaTodosEvts: e.checked });

        if (e.checked) {
            const listaEventos = this.state.lista.listaEvento
            const statusInvalidos = this.state.statusInvalidos;
            const listaEventosValidos = listaEventos.filter(obj => {
                return obj.isReady === true && !statusInvalidos.includes(obj.situacao);
            });

            this.setState({ eventosSelecionados: listaEventosValidos });
        } else {
            this.setState({ eventosSelecionados: [] });
        }
    }

    onEventCheckChange(e) {
        let selectedEvents = [...this.state.eventosSelecionados];
        if (e.checked)
            selectedEvents.push(e.value);
        else
            selectedEvents.splice(selectedEvents.indexOf(e.value), 1);

        this.setState({ eventosSelecionados: selectedEvents });
    }

    onStatusChange(event) {
        this.dt.filter(event.value, 'situacao', 'equals');
        this.setState({ status: event.value });
    }

    onPage(event) {
        this.setState({ loading: true });
        setTimeout(() => {
            const startIndex = event.first;
            this.setState({
                first: startIndex,
                pagina: event.page,
                loading: false
            });
            this.atualizaGrid(true);
        }, 250);
    }

    onChangePaginator(event, options) {
        options.currentPage = event.value
        this.setState({ loading: true, pagina: event.value });
        setTimeout(() => {
            const page = parseInt(this.state.pagina);
            const startIndex = this.state.pagina ? options.rows * page : 0;
            this.setState({
                first: startIndex,
                pagina: event.value,
                loading: false
            });
            this.atualizaGrid(true);
        }, 250);
    }

    renderFiltrosPersonalizados() {
        const style = {
            label: {
                fontSize: 16,
                fontWeight: 'bold',
                display: 'block',
            },
            dropDown: {
                width: '80%'
            },
            inputText: {
                width: '80%'
            },
            inputContainer: {
                paddingTop: '10px',
                paddingBottom: '10px'
            }
        }

        return this.state.filtrosPersonalizados.map(obj => {
            if (obj.tipoFiltro.tipoFiltro == 'TipoServidor') {
                return (
                    <div key={obj.idFiltroPersonalizado} className="pure-u-1-3">
                        <label style={style.label}>Tipo Servidor: </label>
                        <Dropdown
                            value={this.state.tipoServidor}
                            optionLabel="descricao"
                            options={this.state.dropdownTipoServidor}
                            onChange={(e) => { this.handleTipoServidorChange(e.value) }}
                            placeholder="Escolha o Tipo Servidor"
                            style={style.dropDown}
                        />
                    </div>
                )
            }
        })
    }

    renderFooterEnvioLote(desabilitaEnvio) {
        if (this.state.eventosSelecionados.length > 0) {
            desabilitaEnvio = false;
        }
        return (
            <div>
                <Button label="Enviar" icon="pi pi-envelope" onClick={(e) => this.enviarEventosEsocial()} disabled={desabilitaEnvio} />
                <Button label="Cancelar" icon="pi pi-times" onClick={(e) => this.setState({ evtselecionados: false })} className="p-button-secondary" />
            </div>
        );
    }

    renderFooterMudarAcao(desabilitaMudarAcao) {
        if (this.state.eventosSelecionados.length > 0) {
            desabilitaMudarAcao = false;
        }
        return (
            <div>
                <Button
                    label="Mudar"
                    icon={
                        <svg width="25px" height="25px" viewBox="-9.6 -9.6 67.20 67.20" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="48" height="48" fill="white" fill-opacity="0.01"></rect> <path d="M18 31H38V5" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M30 21H10V43" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M44 11L38 5L32 11" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M16 37L10 43L4 37" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    }
                    onClick={(e) => this.mudarAcaoEventos()}
                    disabled={desabilitaMudarAcao}
                />
                <Button label="Cancelar" icon="pi pi-times" onClick={(e) => this.setState({ modalMudarAcao: false })} className="p-button-secondary" />
            </div>
        );
    }

    renderMudarAcao() {
        const style = {
            dropDown: {
                width: '80%'
            },
        }
        const dropdownOptions = [];
        dropdownOptions.push({label: 'Inclusão', value: 'INCLUSAO'});
        dropdownOptions.push({label: 'Alteração', value: 'ALTERACAO'});
        if(this.state.dropdownGrupoEventos.find(item => item.value === this.state.grupo)?.label === 'TABELA') {
            dropdownOptions.push({label: 'Exclusão', value: 'EXCLUSAO'});
        }

        return (
            <div className="p-grid">
                <div className="p-col">
                    <Dropdown
                        value={this.state.dropdownAcaoModal}
                        options={dropdownOptions}
                        onChange={(e) => { this.handleDropdownAcaoModalChange(e.value) }}
                        placeholder="Escolha a ação"
                        style={style.dropDown}
                    />
                </div>
            </div>
        )
    }

    renderCheckBoxEnvio(rowData, column) {
        const statusInvalidos = this.state.statusInvalidos;
        if (rowData['isReady'] === true && !statusInvalidos.includes(rowData.situacao)) {
            const retorno = (
                <Checkbox onChange={this.onEventCheckChange} value={rowData} checked={this.state.eventosSelecionados.indexOf(rowData) !== -1}></Checkbox>
            )
            return retorno;
        } else {
            const retorno = (
                <Checkbox onChange={this.onEventCheckChange} checked={this.state.checked} disabled={true}></Checkbox>
            )
            return retorno;
        }
    }

    renderCheckSelecionaTodos() {
        return <Checkbox onChange={this.onCheckAllChange} checked={this.state.selecionaTodosEvts}></Checkbox>
    }

    renderEventoReferencia(rowData) {
        let valor = rowData.eventoOrigem !== undefined && rowData.eventoOrigem !== null
            ? (rowData.eventoOrigem.codEventoMensageria.toString() +
                (rowData.eventoOrigem.anoApuracao !== null ?
                    ' - ' + rowData.eventoOrigem.mesApuracao + '/' + rowData.eventoOrigem.anoApuracao
                    :
                    ''
                )
            )
            :
            '-';
        return valor !== '-' ?
            <span className='p-text-default'>{valor}</span>
            :
            valor;
    }
    renderIDEventoFila(rowData) {
        return <span className='p-text-default'>{rowData.idEventoFila}</span>
    }
    renderLote(rowData) {
        return <LoteView idLote={rowData.idLote} useLink={true} />
    }
    renderDataCadastro(rowData) {
        return <span className='p-text-default'>{rowData.dataInclusaoFila}</span>
    }
    renderDataObjeto(rowData) {
        return rowData.dataObjeto !== undefined ?
            <span className='p-text-default'>{rowData.dataObjeto}</span>
            :
            '-'
    }
    renderDetalhe(rowData) {
        return rowData.objetoAlvoEvento !== null ?
            <span className='p-text-default'>{rowData.objetoAlvoEvento}</span>
            :
            '-'
    }
    renderMesAnoApuracao(rowData) {
        return rowData.mesAnoApuracao !== null ?
            <span className='p-text-default'>{rowData.mesAnoApuracao}</span>
            :
            '-'
    }
    renderIndApuracao(rowData) {
        return rowData.indApuracao !== null ?
            <Badge value={rowData.indApuracao} className='p-badge-info-outlined' />
            :
            '-'
    }
    renderEstadoEvento(rowData) {
        return <CheckEventoProntoNovo eventoFila={rowData} />
    }
    renderTipoEvento(rowData) {
        return <Badge value={rowData.codEventoEsocial} className='p-badge-info-outlined'/>;
    }
    renderAcao(rowData) {
        return <span className='p-text-default'>{rowData.acao}</span>
    }

    renderReferenciaAlteracao(rowData)
    {
        return rowData.referencia_Alteracao ?? (
            <span className='p-text-default'>-</span>
          )
    }
    renderStatusEvento(rowData) {
        let status = 'info';

        switch (rowData.situacao) {
            case 'RECEBIDO':
                status = 'success';
                break;
            case 'REJEITADO':
                status = 'danger';
                break;
            case 'PENDENTE':
                status = 'warning';
                break;
            default:
                break;
        }

        return (
            <div>
                <Badge value={rowData.situacao} severity={status} className='p-badge-square' />
            </div>

        );
    }

    renderDataTable() {
        const templatePaginator = {
            layout: 'CurrentPageReport PrevPageLink NextPageLink',
            'CurrentPageReport': (options) => {
                const totalReg = options.totalRecords === null ? 0 : options.totalRecords
                const dropdownOptions = [];
                const totalPages = options.totalPages === 1 ? 0 : options.totalPages - 1
                for (let i = 0; i <= totalPages; i++) {
                    const item = {
                        label: i + 1,
                        value: i
                    };

                    dropdownOptions.push(item);
                }

                return (
                    <>
                        <div className="p-grid">
                            <div className="p-col">
                                <Badge value={`Registros: ${options.first} até ${options.last} de ${totalReg}`} severity="info" className='p-badge-square ' />
                                <Dropdown value={options.currentPage - 1} options={dropdownOptions} onChange={(e) => this.onChangePaginator(e, options)} />
                            </div>
                        </div>
                    </>
                )
            }
        };

        return (
            <div className="content-section implementation">
                <DataTable value={this.state.lista.listaEvento}
                    className="p-datatable-sm"
                    id="tblEventos"
                    emptyMessage={
                        <div style={{
                            fontWeight: 'bold',
                            fontSize: '18px',
                            textAlign: 'center',
                            padding: '10px'
                        }}>
                            {this.state.emptyMessage}
                        </div>
                    }
                    lazy={true}
                    rows={this.state.rows}
                    onPage={this.onPage.bind(this)}
                    loading={this.state.loading}
                    autoLayout={true}
                    first={this.state.first}
                    totalRecords={this.state.total}
                    paginator={true}
                    paginatorTemplate={templatePaginator}
                    scrollable scrollHeight="380px"
                    selection={this.state.selecionados}
                    onSelectionChange={e => this.setState({ selecionados: e.value })}
                >
                    <Column body={this.renderCheckBoxEnvio} style={{ width: '3em' }} header={this.renderCheckSelecionaTodos()} />
                    <Column body={this.renderIDEventoFila} header="ID" style={{ width: '4em' }} />
                    <Column body={this.renderLote} header="ID Lote" style={{ width: '3em' }} />
                    <Column body={this.renderDataCadastro} header="Dt. Evento" style={{ width: '6em', textAlign: 'center' }} />
                    <Column body={this.renderDataObjeto} header="Dt. Objeto" style={{ textAlign: 'center' }} />
                    <Column body={this.renderEventoReferencia} header="Evento Ref." style={{ textAlign: 'center' }} />
                    <Column body={this.renderReferenciaAlteracao} header="Ref. Alteração" style={{ textAlign: 'center', width:'4em' }}/>
                    <Column body={this.renderTipoEvento} header="Evento" style={{ textAlign: 'center' }} />
                    <Column body={this.renderAcao} header="Ação" />
                    <Column body={this.renderDetalhe} header="Detalhe" />
                    <Column body={this.renderMesAnoApuracao} header="Período" style={{ textAlign: 'center' }} />
                    <Column body={this.renderIndApuracao} header="Ind. Apuração" />
                    <Column body={this.renderEstadoEvento} header="Apto p/ Envio" style={{ textAlign: 'center' }} />
                    <Column body={this.renderStatusEvento} header="Status" />
                    <Column body={this.actionTemplate} header="Opções" style={{ textAlign: 'center', width: '12em' }} />
                </DataTable>
            </div>
        )
    }

    resetaModalEnvioLote() {
        this.setState({ evtselecionados: false, realizandoEnvio: false, blkAnimacaoEnvioGeral: 'none' });
    }

    renderLoandingValidacaoEventos() {
        return (
            <div>
                <h3>Validando eventos selecionados. Por favor, aguarde.</h3>
                <ProgressBar mode="indeterminate" />
            </div>
        )
    }

    handleCtrlEnter = (e) => {
        if (e.ctrlKey && e.key === 'Enter') {
            this.atualizaGrid(false);
          console.log('Botão clicado ou atalho "Ctrl + Enter" pressionado');
        }
      }

    render() {

        const keyMap = {
            'CTRL_ENTER': 'ctrl+enter',
          };

          const handlers = {
            'CTRL_ENTER': this.handleCtrlEnter,
          };

        const acao = [{ label: "Todos", value: "" }, { label: 'Inclusão', value: 'INCLUSAO' }, { label: 'Alteração', value: 'ALTERACAO' }, { label: 'Exclusão', value: 'EXCLUSAO' }];

        const style = {
            label: {
                fontSize: 16,
                fontWeight: 'bold',
                display: 'block',
            },
            dropDown: {
                width: '80%'
            },
            inputText: {
                width: '80%'
            },
            inputContainer: {
                paddingTop: '10px',
                paddingBottom: '10px'
            },
            toolbar: {
                margin: 0,
                padding: 0,
            },
        }

        const mes = [
            { label: "Todos", value: "" },
            { label: "Janeiro", value: 1 },
            { label: "Fevereiro", value: 2 },
            { label: "Março", value: 3 },
            { label: "Abril", value: 4 },
            { label: "Maio", value: 5 },
            { label: "Junho", value: 6 },
            { label: "Julho", value: 7 },
            { label: "Agosto", value: 8 },
            { label: "Setembro", value: 9 },
            { label: "Outubro", value: 10 },
            { label: "Novembro", value: 11 },
            { label: "Dezembro", value: 12 },
        ];

        const currentYear = new Date().getFullYear();
        const anos = [];
        anos.push({ label: "Todos", value: "" });
        for (let year = currentYear; year >= 2018; year--) {
            anos.push(year);
        }

        const indApuracao = [{ label: "Todos", value: "" }, { label: 'Mensal', value: 1 }, { label: 'Anual', value: 2 }]
        const status = [{ label: "Todos", value: "" }, { label: 'Rejeitado', value: 'REJEITADO' }, { label: 'Pendente', value: 'PENDENTE' }, { label: 'Aguardando retorno', value: 'AGUARDANDO_RETORNO' }, { label: 'Recebido', value: 'RECEBIDO' }, { label: 'Duplicado', value: 'DUPLICADO' }, { label: 'Alterado', value: 'ALTERADO' }, { label: 'Excluido', value: 'EXCLUIDO' }];
        const situacao = [{ label: "Todos", value: "" }, { label: 'Pronto', value: 1 }, { label: 'Inconsistente', value: 0 }];

        let grupoSelecionado = this.state.dropdownGrupoEventos.find(item => item.value === this.state.grupo)?.label
        const eventoExlcusaoSelecionado = this.state.dropdownEventos.find(item => item.value === this.state.evento)?.label.includes('3000');

        const showFiltrosFolha = grupoSelecionado === 'PERIÓDICO' || eventoExlcusaoSelecionado;
        const showFiltrosTrabalhador =  grupoSelecionado !== undefined && grupoSelecionado !== null && grupoSelecionado !== 'TABELA';

        const rightContents = (
            <React.Fragment>
                <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
                    <Button icon="pi pi-refresh" style={{ margin: '4px' }} onClick={() => this.atualizaGrid(false)} tooltip="Filtrar" tooltipOptions={{ position: 'right' }} label="Filtrar" />
                </GlobalHotKeys>
            </React.Fragment>
        );

        return (
            <div>
                <Messages ref={(el) => this.messages = el}></Messages>
                <div className="content-section introduction">
                    <div className="feature-intro">
                        <h4>Eventos eSocial</h4>
                    </div>
                </div>
                <div>
                    <Panel header="Filtros" toggleable={true} collapsed={this.state.isCollapsed} onToggle={(e) => this.handleIsCollapsed(e.value)}>
                        <div>
                            <div style={style.inputContainer} className="pure-g">
                                <div className="pure-u-1-3">
                                    <label style={style.label}>Grupo Evento: </label>
                                    <Dropdown
                                        value={this.state.grupo}
                                        options={this.state.dropdownGrupoEventos}
                                        onChange={(e) => this.handleGrupoEventoChange(e.value)}
                                        placeholder="Escolha o grupo"
                                        style={style.dropDown}
                                    />
                                </div>
                                <div className="pure-u-1-3">
                                    <label style={style.label}>Evento: </label>
                                    <Dropdown
                                        value={this.state.evento}
                                        options={this.state.dropdownEventos}
                                        onChange={(e) => this.handleEventoChange(e.value)}
                                        placeholder="Escolha o evento"
                                        style={style.dropDown}
                                    />
                                </div>
                                <div className="pure-u-1-3">
                                    <label style={style.label}>ID Lote: </label>
                                    <InputNumber
                                        id="idLote"
                                        value={this.state.idLote}
                                        onChange={(e) => this.handleIdLoteChange(e.value)}
                                        mode="decimal"
                                        useGrouping={false} //adicionado para tirar agrupamento na tela
                                    />
                                </div>
                            </div>
                            {eventoExlcusaoSelecionado ? 
                                <div style={style.inputContainer} className="pure-g">
                                    <div className="pure-u-1-3">
                                        <label style={style.label}>Evento Excluido: </label>
                                        <Dropdown
                                            value={this.state.eventoExcluido}
                                            options={this.state.dropdownEventosExcluidos}
                                            onChange={(e) => this.handleEventoExcluidoChange(e.value)}
                                            placeholder="Escolha o evento"
                                            style={style.dropDown}
                                        />
                                    </div>
                                </div> : ''
                            }

                            <div style={style.inputContainer} className="pure-g">
                                <div className="pure-u-1-3">
                                    <label style={style.label}>Status: </label>
                                    <Dropdown
                                        value={this.state.status}
                                        options={status}
                                        onChange={(e) => this.handleStatusChange(e.value)}
                                        placeholder="Escolha o status"
                                        style={style.dropDown}
                                    />
                                </div>
                                <div className="pure-u-1-3">
                                    <label style={style.label}>Ação: </label>
                                    <Dropdown
                                        value={this.state.acao}
                                        options={acao}
                                        onChange={(e) => this.handleAcaoChange(e.value)}
                                        placeholder="Escolha a ação"
                                        style={style.dropDown}
                                    />
                                </div>
                                <div className="pure-u-1-3">
                                    <label style={style.label}>Detalhes: </label>
                                    <InputText
                                        id="objDetalhe"
                                        value={this.state.objDetalhe}
                                        onChange={(e) => this.handleObjDetalheChange(e.target.value)}
                                        style={style.inputText}
                                    />
                                </div>
                            </div>
                            <div style={style.inputContainer} className="pure-g">
                                <div className="pure-u-1-3">
                                    <label style={style.label}>ID Evento: </label>
                                    <InputNumber
                                        id="idEventoFila"
                                        value={this.state.idEventoFila}
                                        onChange={(e) => this.handleIdEventoFilaChange(e.value)}
                                        mode="decimal"
                                        useGrouping={false} //adicionado para tirar agrupamento na tela
                                    />
                                </div>
                                <div className="pure-u-1-3">
                                    <label style={style.label}>Situação do Evento: </label>
                                    <Dropdown
                                        value={this.state.situacao}
                                        options={situacao}
                                        onChange={(e) => this.handleSituacaoChange(e.value)}
                                        placeholder="Escolha a situação"
                                        style={style.dropDown}
                                    />
                                </div>
                                {this.renderFiltrosPersonalizados()}
                            </div>
                            {showFiltrosFolha && (
                                <div style={style.inputContainer} className="pure-g">
                                    <div className="pure-u-1-3">
                                        <label style={style.label}>Mês: </label>
                                        <Dropdown
                                            value={this.state.mesPerApur}
                                            options={mes}
                                            onChange={(e) => this.handleMesChange(e.value)}
                                            placeholder="Escolha o mês"
                                            style={style.dropDown}
                                        />
                                    </div>
                                    <div className="pure-u-1-3">
                                        <label style={style.label}>Ano: </label>
                                        <Dropdown
                                            value={this.state.anoPerApur}
                                            options={anos}
                                            onChange={(e) => this.handleAnoChange(e.value)}
                                            placeholder="Escolha o ano"
                                            style={style.dropDown}
                                        />
                                    </div>
                                    <div className="pure-u-1-3">
                                        <label style={style.label}>Indicação de Apuração: </label>
                                        <Dropdown
                                            value={this.state.indApuracao}
                                            options={indApuracao}
                                            onChange={(e) => this.handleIndApuracaoChange(e.value)}
                                            placeholder="Escolha a indicação de apuração"
                                            style={style.dropDown}
                                        />
                                    </div>
                                </div>
                            )}
                            {showFiltrosTrabalhador && (
                                <div style={style.inputContainer} className="pure-g">
                                    <div className="pure-u-1-3">
                                        <label style={style.label}>CPF: </label>
                                        <InputText
                                            id="cpf"
                                            value={this.state.cpf}
                                            onChange={(e) => this.handleCpfChange(e.target.value)}
                                            style={style.inputText}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </Panel>
                    <Toolbar right={rightContents} style={style.toolbar} />

                    <div style={{ padding: '3px' }}>
                        <Button
                            style={{ margin: '4px' }}
                            tooltip="Eventos de Tabela"
                            icon="pi pi-info"
                            className="p-button-info"
                            onClick={(e) => this.setState({ visible: true, xmlPreview: 'teste' })}
                        />
                        <Button
                            icon="pi pi-refresh"
                            style={{ margin: '4px' }}
                            onClick={(e) => this.atualizaGrid(false)}
                            tooltip="Atualizar"
                            className="p-button-info"
                            tooltipOptions={{ position: 'right' }}
                        />
                        <Button
                            icon={
                                <svg width="25px" height="25px" viewBox="-9.6 -9.6 67.20 67.20" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="48" height="48" fill="white" fill-opacity="0.01"></rect> <path d="M18 31H38V5" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M30 21H10V43" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M44 11L38 5L32 11" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M16 37L10 43L4 37" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                            }
                            style={{ margin: '4px' }}
                            tooltip="Mudar ação"
                            className="p-button-info"
                            onClick={(e) => this.handleMudarAcaoClick()}
                        />
                        <Button
                            icon="pi pi-envelope" style={{ margin: '4px' }} tooltip="Enviar selecionados"
                            className="p-button-success"
                            onClick={(e) => this.handleEnvioLoteClick()}
                        />
                        <Dialog header="Eventos de Tabela Esocial" visible={this.state.visible} style={{ width: '50vw' }} modal={true} onHide={(e) => this.setState({ visible: false })}>
                            <DataTable value={this.state.lista.eventos}>
                                <Column field="codeventomensageria" style={{ width: '20%', textAlign: 'center' }} header="Cod. Evento" />
                                <Column field="nomeEvento" header="Evento" style={{ width: '40%', textAlign: 'left' }} />
                                <Column field="ordem" header="Ordem" style={{ width: '10%', textAlign: 'center' }} />
                                <Column field="ajuda" header="Documentação" style={{ textAlign: 'center' }} />
                            </DataTable>
                        </Dialog>
                        <Dialog
                            header="Processando Requisição. Por favor, Aguarde."
                            visible={this.state.showProgressBar}
                            style={{ width: '50vw' }}
                            onHide={(e) => this.setState({ showProgressBar: false })}
                            dismissableMask={false}
                        >
                            <ProgressBar mode="indeterminate" />
                        </Dialog>
                        <Dialog className="dialog-demo" header={"Deseja enviar os " + this.state.eventosSelecionados.length + " eventos selecionados ? "} visible={this.state.evtselecionados} style={{ width: '50vw' }}
                            onHide={(e) => this.setState({ evtselecionados: false, realizandoEnvio: false, blkAnimacaoEnvioGeral: 'none' })}
                            footer={this.state.realizandoEnvio || this.state.isValidating ? '' : this.renderFooterEnvioLote(this.state.desabilitaEnvio)} maximizable>

                            {
                                this.state.isValidating ? this.renderLoandingValidacaoEventos() :
                                    <ScrollPanel className="custom" style={{ height: '558px', textAlign: 'center', display: this.state.blkEnvioGeral }} id="divTabelaEventosSelecionados" >
                                        <EnvioLote eventos={this.state.eventosSelecionados} />
                                    </ScrollPanel>
                            }

                            <div style={{ display: this.state.blkAnimacaoEnvioGeral }} id="divEvtEnviados">
                                <div>
                                    <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="#EEEEEE" />
                                    <em>Enviando os eventos selecionados.</em>
                                </div>
                            </div>

                        </Dialog>
                        <Dialog header={"Deseja mudar a ação de " + this.state.eventosSelecionados.length + " eventos selecionados ? "} visible={this.state.modalMudarAcao} style={{ width: '50vw' }}
                            onHide={(e) => this.setState({ modalMudarAcao: false})}
                            footer={this.renderFooterMudarAcao(this.state.desabilitaMudarAcao)}>
                            {this.renderMudarAcao()}
                        </Dialog>
                    </div>
                </div>

                {this.renderDataTable()}
            </div>
        );
    }

}
