import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Messages } from 'primereact/messages';
import { MenuBar } from '../../../componentes/MenuBar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Panel } from 'primereact/panel';
import { TabView, TabPanel } from 'primereact/tabview';
import { TblImportacao } from '../../Importacao/TblImportacao';
import WizardImportacao from '../../Importacao/Wizards/WizardImportacao';
import TabGeracaoIndividual from '../../Folpag/tabs/TabGeracaoIndividual';
import CargaRubrica from '../../Importacao/CargaRubrica';
import TabRetificacaoMassa from '../../Folpag/tabs/TabRetificacaoMassa';
import TabExclusaoEvtsFol from '../../Folpag/tabs/TabExclusaoEvtsFol';
import usuarioService from '../../../services/usuarioService';

export class CargaInicial extends Component {
  constructor() {
    super();
    this.state = {
      home: { icon: 'pi pi-home' },
      expandedRows: null,
      usuario: {},
      agendamentos: [],
      isImportacao: false,
      wzdCargaInicial: 'block',
      wzdImportacao: 'none',
      tipoEvento: "1",
      grupo: 1,
      activeIndex: 0,
      items: [{
        label: 'Eventos',
        items: [
          { label: 'Tabela', icon: 'pi pi-fw pi-upload', command: () => { this.setState({ activeIndex: 0 }) } },
          { label: 'Não Periódicos', icon: 'pi pi-fw pi-calendar', command: () => { this.setState({ activeIndex: 1 }) } }
          /*{label: 'Periódicos', icon: 'pi pi-fw pi-calendar', command:()=>{ this.setState({activeIndex:2})}}*/
        ]
      }
      ], layout: 'list', eventos: [], frmEvento: {}
    };
    this.clearMessages = this.clearMessages.bind(this);
  }
  alteraNaturezaImportacao(e) {
    let importacao = e.value;

    if (importacao) {
      this.messages1.clear();
      this.setState({ wzdCargaInicial: 'none', wzdImportacao: 'block' });
      this.messages2.show({ severity: 'success', summary: 'Importação eSocial 1.0: ', detail: ' Os eventos serão importados da versão anterior a mensageria.', sticky: true });
    } else {
      this.messages2.clear();
      this.setState({ wzdCargaInicial: 'block', wzdImportacao: 'none' });
      this.messages1.show({ severity: 'info', summary: 'Carga inicial de eventos: ', detail: ' Os eventos serão gerados a partir dos dados do MENTORH.', sticky: true });
    }
    this.setState({ isImportacao: importacao });
  }
  componentDidMount() {
    this.consultaUsuario();
    if (!this.state.isImportacao) {
      this.messages1.show({ severity: 'info', summary: 'Carga inicial de eventos: ', detail: ' Os eventos serão gerados a partir dos dados do MENTORH.', sticky: true });
    }
  }
  consultaUsuario() {
    usuarioService.consultaUsuario((usuario) => {
      this.setState({ usuario });
    });
  }
  clearMessages() {
    console.log(this.messages);
    this.messages1.clear();
  }
  render() {
    const items = [
      { label: 'Home', icon: 'pi pi-home', url: '/home' },
      { label: 'Administração', icon: 'pi pi-home', url: '/home' },
      { label: 'Importação', icon: 'pi pi-home', url: '/importacao' }
    ];
    return (
      <div style={{ marginTop: '30px' }}>
        <MenuBar usuario={this.state.usuario} />
        <div className="content">
          <div className="content align-center">

            <div className="pure-u-5-5">
              <Panel header="Importação de Eventos">
                <BreadCrumb home="principal" model={items} />
                <div className="pure-g" style={{ marginTop: '25px' }}>
                  <div className="pure-u-5-5">
                    <Messages ref={(el) => this.messages1 = el}></Messages>
                    <Messages ref={(el) => this.messages2 = el}></Messages>
                    {/* <label>Importar da versão anterior: </label> <InputSwitch checked={this.state.isImportacao} onChange={(e) => this.alteraNaturezaImportacao(e)} /> */}

                  </div>
                  <div className="pure-u-5-5" style={{ border: '1px solid #ccc', padding: '12px 12px 12px 12px', display: this.state.wzdCargaInicial }}>
                    <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                      <TabPanel header="Eventos de Tabela">
                        <TblImportacao idGrupo="1" />
                      </TabPanel>
                      <TabPanel header="Eventos não Periódicos">
                        <TblImportacao idGrupo="2" />
                      </TabPanel>
                       <TabPanel header="Importação Individual de Eventos">
                          <TabGeracaoIndividual/>
                        </TabPanel>
                        <TabPanel header="Retificação Eventos de Folha">
                          <TabRetificacaoMassa/>
                        </TabPanel>
                        <TabPanel header="Exclusão Eventos de Folha">
                          <TabExclusaoEvtsFol/>
                        </TabPanel>
                    </TabView>
                  </div>
                  <div className="pure-u-4-5" style={{ border: '1px solid #ccc', padding: '12px 12px 12px 12px', display: this.state.wzdImportacao }}>
                    <h5>Importação eSocial 1.0</h5>
                    <div>
                      <em>A importação eSocial 1.0 irá importar os eventos existentes na versão anterior (Mensageria 1.0). Selecione essa opção apenas se exisitir eventos na versão anterior.</em>
                    </div>
                    <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                      <TabPanel header="Eventos de Tabela">
                        <WizardImportacao idGrupo={1} />
                      </TabPanel>
                      <TabPanel header="Eventos não Periódicos">
                        <WizardImportacao idGrupo={2} />
                      </TabPanel>
                    </TabView>
                  </div>
                </div>
              </Panel>
            </div>
          </div>
        </div>
        <div className="footer l-box is-center">
          Copyright © Todos os direitos reservados.
            </div>

      </div>
    );
  }
}
